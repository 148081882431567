import React from 'react';
import styled from 'styled-components';
import {
    LightTheme,
    MonoTheme,
    JadeTheme,
    DarkTheme,
} from '../components/Theme';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Intro from '../components/Intro';
import SecondaryCTAs from '../components/SecondaryCTAs';
import BoardMembers from '../components/BoardMembers';
import Footer from '../components/Footer';
import Metadata from '../components/Metadata';
import useAboutUsPage from '../utils/useAboutUsPageQuery';
import notNull from '../utils/notNull';

const Section = styled.div`
    display: grid;
    grid-template-columns: 100%;

    &[data-section='banner'] {
        grid-row-gap: var(--sectionSpacing10);
    }

    &[data-section='intro'] {
        grid-row-gap: var(--sectionSpacing11);
        padding: 0 0 var(--sectionSpacing10);
    }

    &[data-section='board'] {
        grid-row-gap: var(--sectionSpacing11);
        padding: var(--sectionSpacing10) 0;
    }
`;

export default function AboutUs(): React.ReactElement {
    const { banner, intro, sideBySideCTAs, pageMetadata } = useAboutUsPage();
    const filteredCTAs = sideBySideCTAs && sideBySideCTAs.filter(notNull);

    return (
        <React.Fragment>
            {pageMetadata && <Metadata pageMetadata={pageMetadata} />}

            <LightTheme>
                <Navbar />
                <Section data-section="banner">
                    {banner && <Banner {...banner} />}
                    <Section data-section="intro">
                        {intro && (
                            <MonoTheme>
                                <Intro contentRaw={intro} />
                            </MonoTheme>
                        )}
                    </Section>
                </Section>
            </LightTheme>

            {filteredCTAs && filteredCTAs.length > 0 && (
                <DarkTheme>
                    <SecondaryCTAs ctas={filteredCTAs} />
                </DarkTheme>
            )}
            <JadeTheme>
                <Section data-section="board">
                    <BoardMembers />
                </Section>
            </JadeTheme>
            <LightTheme>
                <Footer />
            </LightTheme>
        </React.Fragment>
    );
}
