import { graphql, useStaticQuery } from 'gatsby';
import {
    AboutUsPageQuery,
    AboutUsPageQuery_sanityAboutUsPage as AboutUsPage,
} from '../__generated__/AboutUsPageQuery';

export default function useAboutUsPageQuery(): AboutUsPage {
    const query: AboutUsPageQuery = useStaticQuery(graphql`
        query AboutUsPageQuery {
            sanityAboutUsPage(_id: { eq: "aboutUsPage" }) {
                pageMetadata {
                    ...PageMetadataFragment
                }
                banner {
                    ...BannerFragment
                }
                intro: _rawIntro
                sideBySideCTAs {
                    ...SideBySideCTAFragment
                }
                boardMembers {
                    _key
                    name
                    role
                    phone
                    email
                    image {
                        ...ImageFragment
                    }
                }
            }
        }
    `);

    if (query.sanityAboutUsPage) {
        return query.sanityAboutUsPage;
    }

    throw new Error('Unfound AboutUsPage');
}
