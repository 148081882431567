import React from 'react';
import { Link as _Link } from 'gatsby';
import styled from 'styled-components';
import { SideBySideCTAFragment } from '../__generated__/SideBySideCTAFragment';
import { Viewport } from '../Settings';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    --borderWidth: 3px;
    grid-gap: var(--borderWidth);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        grid-template-columns: 100%;
        padding: 0 var(--sizeGutters);
    }
`;

const Item = styled.div`
    display: grid;
    grid-template-columns: repeat(24, minmax(0, 1fr));
    outline: var(--borderWidth) solid var(--foregroundColor);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        grid-template-columns: 100%;
        margin-bottom: calc(var(--sizeGutters) + var(--borderWidth));
    }
`;

const Inner = styled.span`
    padding: var(--size6) 0 var(--size9);
    grid-column: 5 / span 16;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size6);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        padding: var(--size6) var(--sizeGutters);
        grid-column: 1 / span 1;
    }

    @media (max-width: ${Viewport.Mobile}px) {
        padding: var(--size4) var(--sizeGutters);
        grid-row-gap: var(--size5);
    }
`;

const Dek = styled(_Link)`
    font-size: var(--size3);
    font-weight: bold;
    color: var(--highlightColor);
    text-decoration: none;
`;

const Hed = styled(_Link)`
    font-size: var(--size8);
    line-height: var(--lineHeightMed);
    color: var(--foregroundColor);
    text-decoration: none;

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size4);
    }
`;

interface Props {
    ctas: SideBySideCTAFragment[];
}

function SecondaryCTAs({ ctas }: Props): React.ReactElement {
    return (
        <Container>
            {ctas.map(
                ({
                    _key: key,
                    hed,
                    dek,
                    url,
                }: SideBySideCTAFragment): React.ReactElement => (
                    <Item key={key || ''}>
                        <Inner>
                            <Dek to={url || '/'}>{dek}</Dek>
                            <Hed to={url || '/'}>{hed}</Hed>
                        </Inner>
                    </Item>
                )
            )}
        </Container>
    );
}

export default React.memo(SecondaryCTAs);
